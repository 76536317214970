<template>
  <kurcc-data-table-page-template :data-table-title="getDataTableTitle"
                                  :default-item="defaultItem"
                                  :delete-dialog-title="$vuetify.lang.t('$vuetify.pages.dashboard.branches.deleteBranchMessage')"
                                  :editDialogTitle="$vuetify.lang.t('$vuetify.pages.dashboard.branches.editBranch')"
                                  :get-all-action-additional-params="prepareAdditionalParams"
                                  :headers="headers"
                                  :toolbar-button-dialog-title="$vuetify.lang.t('$vuetify.pages.dashboard.branches.addBranch')"
                                  :toolbar-button-text="$vuetify.lang.t('$vuetify.pages.dashboard.branches.addBranch')"
                                  :update-dialog-title="$vuetify.lang.t('$vuetify.pages.dashboard.branches.editBranch')"
                                  actions-suffix="Branch"
                                  initial-sort-by-column="number">
    <template v-slot:dialog="{item}">
      <kurcc-branch-dialog :item="item"/>
    </template>
  </kurcc-data-table-page-template>
</template>

<script>
import Branch from '@/modules/app/models/branch'

export default {
  name: 'KurccBranchesPage',
  components: {
    KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
    KurccBranchDialog: () => import('@/modules/dashboard/components/KurccBranchDialog')
  },
  data () {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.branchNumber'),
          align: 'start',
          value: 'number'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.brandName'),
          value: 'brand_name_en'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.address'),
          value: 'full_address',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.managerName'),
          value: 'manager_name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.managerPhoneNumber'),
          value: 'manager_phone_number'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.grade'),
          value: 'last_done_inspection.grade'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.tableColumns.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      defaultItem: new Branch(),
      siteData: undefined
    }
  },
  computed: {
    prepareAdditionalParams () {
      return [`site_ids=["${this.$auth.user().site.id}"]`]
    },
    getDataTableTitle () {
      return this.$vuetify.lang.t('$vuetify.pages.dashboard.branches.branchList')
    }
  }
}
</script>
